<template>
  <div class="container">
    <v-row>
      <v-col />
    </v-row>
    <v-container>
      <v-container v-if="filteredProducts && filteredProducts.length > 0">
        <v-row>
          <v-col :cols="getColumns()">
            <v-text-field v-model="search" clearable flat solo-inverted hide-details background-color="blue lighten-2" prepend-inner-icon="mdi-magnify" label="חיפוש מוצר"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col/>
      </v-row>
      <v-row v-if="loader">
        <v-col v-for="(loaderItem, index) in skeletonLoaders" :key="index" :cols="getColumns()">
          <v-card>
            <v-skeleton-loader type="card-heading, image, list-item-three-line"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(product, index) in filteredProducts" :key="index" :cols="getColumns()">
          <v-card v-if="!loader" class="mb-4" @click="redirectToInfoPage(product.id)">
            <v-card-title class="text-h5">{{ product.title }}</v-card-title>
            <v-divider />
            <v-img :src="getProductImage(product.uploadImageS3Path)" height="250"></v-img>
            <v-row class="text-center" justify="center">
              <v-col :cols="6">
                <v-card-text>נרכשו: {{ product.ticketsBought | numberWithCommas }}</v-card-text>
              </v-col>
              <v-col :cols="6">
                <v-card-text> מחיר: {{ product.ticketPrice | numberWithCommas }} ₪</v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-if="!loader && filteredProducts.length === 0" cols="12">
          <div class="text-center" justify="center">לא נמצאו מוצרים</div>
          <v-img src=".././assets/no_data.png" max-width="400" class="mx-auto"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  export default {
    name: 'StorePage',
    data() {
      return {
        search: '',
        loader: true,
        products: [],
        error: false,
        skeletonLoaders: []
      }
    },
    async created() {
      this.skeletonLoaders = [1, 2, 3, 4, 5, 6];

      try {
        const data = await this.getAllProducts();
          if (data && data.length > 0) {
              console.log("all products: " + JSON.stringify(data));
              this.products = data
          }
      } catch (error) {
          console.error("Error fetching products:", error);
      } finally {
          this.loader = false;
      }
    },
    computed: {
      filteredProducts() {
        return this.products.filter(product => {
          const searchRegex = new RegExp(this.search, 'i');
          return searchRegex.test(product.title) || searchRegex.test(product.description);
        });
      },
    },
    filters: {
      numberWithCommas(value) {
        if (typeof value !== 'number') {
          return value;
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
    },
    methods: {
    redirectToInfoPage(productId){
        this.$router.push(`/store/info/${productId}`)
    },
      getAllProducts() {
        return fetch(`${process.env.VUE_APP_WINIT_URL}/v1/products`)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log('Failed to retrieve products, resp status: ' + response.status);
          }
        }).catch(error => console.log(error))
      },
      getColumns() {
        return this.$vuetify.breakpoint.xsOnly ? 12 : 4;
      },
      getProductImage(imagesUrl) {
        if (!imagesUrl || imagesUrl.length === 0) {
          return require('@/assets/no_image.png')
        }
        const firstImageUrl = imagesUrl[0]
        if (firstImageUrl && this.isFileExistsInS3(firstImageUrl)) {
          return firstImageUrl;
        }
        if (this.confImageUrls && this.confImageUrls.length > 0) {
          let file = this.confImageUrls.find(file => file.fileName === 'no_image.png')
          if (file && this.isFileExistsInS3(file.filePath)) {
            return file.filePath
          }
        }
        return require('@/assets/no_image.png');
      },
      async isFileExistsInS3(filePath) {
        try {
          const response = await fetch(filePath);
          if (response.status === 200) {
            console.log("check s3 is ok")
            return true
          }
        } catch (error) {
          console.error(error);
        }
        console.log("check s3 is not ok")
        return false;
      },
    },
  }
</script>