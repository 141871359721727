<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="2">
          <v-btn color="#b0c7e3" rounded @click="openNewProductDialog">
            <v-icon dark> mdi-plus</v-icon> פרסום מוצר חדש
          </v-btn>
        </v-col>
        <v-col cols="8">
          <v-img src="./assets/logo.png" max-height="150" contain @click="redirect('store')" :style="{ 'cursor': 'pointer' }"></v-img>
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col />
      </v-row>
      <v-divider />
      <v-divider />
      <v-divider />
      <v-divider />
      <v-divider />
      <v-divider />
      <v-divider />
    </v-container>
    <v-main>
      <router-view></router-view>
      <v-container>
        <AddProductComponent v-if="newProductDialog" @closeNewProductDialog="closeNewProductDialog" />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'App',
    components: {
      AddProductComponent: () => import('./components/AddProductComponent.vue')
    },
    methods: {
      closeNewProductDialog() {
        this.newProductDialog = false
      },
            hasAuthCookie() {
                return document.cookie.match(/^(.*;)?\s*authToken\s*=\s*[^;]+(.*)?$/)
            },
      openNewProductDialog() {
        if (this.hasAuthCookie()) {
          this.newProductDialog = true
        } else {
          console.log('alert the user that hes not logged in and therefore get redirect to login')
          console.log('user is not logged in > redirecting to login')
          alert('על מנת לפרסם מוצר, נדרש לבצע התחברות') // different alert?
          this.redirect('login')
        }
      },
      redirect(path) {
        if (this.$router.history.current.name === path) {
          this.$router.go()
        }
        this.$router.push(`/${path}`)
      },
    },
    data() {
      return {
        newProductDialog: false
      }
    }
  }
</script>