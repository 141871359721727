<template>
    <v-card>
                <v-card-title class="text-h5">{{ selectedProduct?.title }}</v-card-title>
                         <v-card-subtitle>{{ selectedProduct?.category?.hebName }}</v-card-subtitle>
                         <v-divider/>
                         <v-img :src="getProductImage(selectedProduct?.uploadImageS3Path)" height="250"></v-img>
                         <v-card-text>
                           <b>פרטים נוספים: </b>{{ selectedProduct?.description }}
                         </v-card-text>
                         <v-card-text>
                           <div>
                             <b>הועלה בתאריך: </b>{{ selectedProduct?.uploadedAt | fixUploadDateFormat }}
                           </div>
                           <div>
                             <b>מצב המוצר: </b>{{ selectedProduct?.condition?.hebName }}
                           </div>
                           <div>
                             <b> נצפה על ידי: </b>{{ selectedProduct?.seenBy | numberWithCommas }}
                             <v-icon>mdi-human</v-icon>
                           </div>
                           <div>
                             <b> כרטיסים שנרכשו : </b>{{ selectedProduct?.ticketsBought | numberWithCommas }}
                             <v-icon>mdi-ticket</v-icon>
                           </div>
                         </v-card-text>
                         <v-card-actions>
                           <v-row class="text-center" justify="center" >
                                <v-btn color="primary" @click="redirectToPurchasePage(selectedProduct.id)">רכישה {{ selectedProduct?.ticketPrice | numberWithCommas }} ₪ </v-btn>
                           </v-row>
                         </v-card-actions>
                         <v-row>
                           <v-col />
                         </v-row>
        </v-card>
</template>
<script>

export default {
    name: 'ProductInfoPage',
    data() {
        return {
        selectedProduct: {},
        skeletonLoaders: []
        }
    },
async created() {
            const currentPath = window.location.pathname
            const index = currentPath.lastIndexOf('/')
            const selectedProductId = currentPath.substring(index + 1)
            await this.getProduct(selectedProductId).then(data => {
                this.selectedProduct = data
                this.incrementSeenBy(selectedProductId)
            }).catch(error => {
                console.log('Error with getting product info > ' + error)
            })
},
filters: {
      numberWithCommas(value) {
        if (typeof value !== 'number') {
          return value;
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      fixUploadDateFormat: function(value) {
        if (!value) return 'N/A'
        value = value.toString()
        return value.substring(8, 10) + '/' + value.substring(5, 7) + '/' + value.substring(0, 4);
      },
    },
    methods: {
    redirectToPurchasePage(productId) {
            this.$router.push(`/store/purchase/${productId}`)
          },
    async isFileExistsInS3(filePath) {
            try {
              const response = await fetch(filePath);
              if (response.status === 200) {
                console.log("check s3 is ok")
                return true
              }
            } catch (error) {
              console.error(error);
            }
            console.log("check s3 is not ok")
            return false;
          },
     getProductImage(imageUrl) {
            if (imageUrl && this.isFileExistsInS3(imageUrl)) {
              return imageUrl;
            }
            return require('@/assets/no_image.png');
          },
           incrementSeenBy(productId) {
                  const api = `${process.env.VUE_APP_WINIT_URL}/v1/products/actions/seenBy/increment/${productId}`
                  fetch(api, {
                    method: 'PUT'
                  }).then(response => {
                    console.log("increment seenBy product request status: " + response.status)
                  }).catch(error => {
                    console.error('There was a problem with increment seenBy param for product: ' + productId, error);
                  });
                },
    async getProduct(productId) {
                console.log('request for get product info with id: ' + productId)
                return await fetch(`${process.env.VUE_APP_WINIT_URL}/v1/products/info/${productId}`).then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    if (response.status === 404) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'מוצר לא קיים, אנא נסה שנית'
                    }
                    if (response.status === 500) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'שגיאה בטעינת המוצר, אנא נסה שנית מאוחר יותר. אם הבעיה ממשיכה אנא פנה לתמיכה'
                    }
                    throw new Error('Error with get product info with status: ' + response.status);
                })
            }
    }
}
</script>