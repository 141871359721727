<template>
    <v-container>
        <v-row class="text-center" justify="center">
            <v-col>
                <h1 class="blue--text text--lighten-1">התחברות</h1>
            </v-col>
        </v-row>
        <v-form v-model="isLoginFormValid">
            <v-row class="text-center" justify="center">
                <v-col cols="6" md="2" xs="6" dir="ltr">
                    <v-text-field v-model="loginRequest.phone" :rules="[rules.required, rules.phoneNumber]"
                                  label="טלפון" class="pa-0" append-icon="mdi-phone-outline"
                                  maxLength=11 @keydown="restrictNonDigits"
                                  ref="phoneNumberInputField" type="tel"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="text-center" justify="center">
                <v-col xs="12" md="6">
                    <v-alert
                            v-model="displayErrorAlert"
                            color="blue"
                            dismissible
                            dark
                            border="top"
                            icon="mdi-alert-circle"
                            transition="scale-transition"
                    >
                        {{ errorMessage }}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <v-btn @click="sendOtp" :disabled="!isLoginFormValid" :loading="buttonLoader">שלח קוד</v-btn>
                </v-col>
            </v-row>
        </v-form>
            <v-container v-if="otpDialog">
                <v-dialog width="50%" height="50%" v-model="otpDialog">
                  <v-card :loading="submitLoader">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container>
                        <v-row class="text-center" justify="center" dir="ltr">
                          <v-col cols="6">
                            <p dir="rtl"> קוד אימות שהתקבל: </p>
                            <v-otp-input dir="ltr" v-model="loginRequest.otpCode" length="6" @finish="otpFinish" :rules="[rules.required, rules.otpCode]" @keydown="restrictNonDigits"></v-otp-input>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col />
                        </v-row>
                        <v-row class="text-center" justify="center">
                          <v-col cols="6">
                            <v-alert v-model="displayErrorAlert" color="blue" dismissible dark border="top" icon="mdi-alert-circle" transition="scale-transition">
                              {{ errorMessage }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-container>
        <v-row>
            <v-col/>
        </v-row>
        <v-row align="center">
            <v-divider></v-divider>
            <v-btn to="/registration">עוד לא רשום? להרשמה לחץ כאן</v-btn>
            <v-divider></v-divider>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'LoginPage',
    created() {
        if (this.hasAuthCookie()) {
            this.redirect('store')
        }
    },
    mounted() {
        this.$refs.phoneNumberInputField.focus();
    },
    watch: {
        "loginRequest.phone": function() {
                if (this.loginRequest.phone && this.loginRequest.phone.length === 3) {
                        this.loginRequest.phone += '-'
                    }
              }
    },
    methods: {
        hasAuthCookie() {
            return document.cookie.match(/^(.*;)?\s*authToken\s*=\s*[^;]+(.*)?$/)
        },
        restrictNonDigits(event) {
            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace']

            if (!allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },
        redirect(path) {
            this.$router.push(`/${path}`)
        },
        async otpFinish() {
                this.submitLoader = true
                await this.login()
              },
        sendOtp() {
            this.otpDialog = true
            this.buttonLoader = true
            this.displayErrorAlert = false
            this.errorMessage = ''
            console.log('send request to send otp - login with phone: ' + this.loginRequest.phone)

            const api = `${process.env.VUE_APP_WINIT_URL}/v1/otp/send?phone=${this.loginRequest.phone}`
            fetch(api, {
                method: 'POST',
                headers: new Map([['Accept', 'application/json'], ['Content-Type', 'application/json']]),
            }).then(response => {
                let status = response.status
                if (status === 200 || status === 429) {
                    this.otpDialog = true
                    this.displayErrorAlert = false
                    console.log('send otp success')
                } else {
                    this.otpDialog = false
                    this.displayErrorAlert = true
                    this.errorMessage = 'שגיאה בשליחת קוד, אנא נסה מאוחר יותר'
                    console.log("status from sendOtp: " + status)
                }
                this.buttonLoader = false
            }).catch(error => {
                console.log(error);
                this.buttonLoader = false
            })
        },
        async login() {
                console.log('going to login: ' + JSON.stringify(this.loginRequest))
                const api = `${process.env.VUE_APP_WINIT_URL}/v1/login`
                await fetch(api, {
                  method: 'POST',
                  headers: new Map([['Accept', 'application/json'], ['Content-Type', 'application/json']]),
                  body: JSON.stringify(this.loginRequest)
                }).then(response => {
                  if (response.status === 200) {
                    alert('התחברת בהצלחה!')
                    this.redirect('store')
                  }
                  alert('error response from login: ' + response.status)
                })
              }
    },
    data() {
        return {
            buttonLoader: false,
            submitLoader: false,
            displayErrorAlert: false,
            errorMessage: '',
            loginRequest: {
                phone: null,
                otpCode: null
            },
            otpDialog: false,
            isLoginFormValid: false,
            rules: {
                required: value => !!value || 'שדה חובה.',
                otpCode: value => {
                            const pattern = /^\s*-?\d{6}\s*$/
                            return pattern.test(value) || '.קוד חייב להיות 6 ספרות'
                          },
                phoneNumber: value => {
                    if (!value) return 'שדה חובה.'
                    if (value.length !== 11) return 'טלפון שגוי'
                    return true
                }
            }
        }
    }
}
</script>