<template>
    <v-card class="mx-auto" max-width="500" style="margin-top: 50px;">
        <v-card-title>פרטי תשלום - {{ this.selectedProduct.title }}</v-card-title>
        <v-form ref="form" v-model="isPurchaseFormValid">
            <v-card-text>
                <v-row justify="end" dir="ltr">
                    <v-col cols="2">
                        <v-img :src="selectedCardImage" contain/>
                    </v-col>
                    <v-col cols="11" sm="5" md="6">
                        <v-text-field minLength=17 maxLength=19 v-model="inputCardNumber" tabindex="1"
                                      :rules="[rules.required]" label="מספר כרטיס"
                                      type="tel" @keydown="restrictNonDigits">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="start">
                    <v-col sm="3" md="3" dir="ltr">
                        <v-text-field minLength=5 maxLength=5 v-model="purchaseRequest.expiry" tabindex="2"
                                      :rules="[rules.required]" label="תוקף"
                                      type="tel" @keydown="restrictNonDigits">
                        </v-text-field>
                    </v-col>
                    <v-col sm="3" md="3" dir="ltr">
                        <v-text-field minLength=3 maxLength=4 v-model="purchaseRequest.cvv" tabindex="3"
                                      :rules="[rules.required]" label="קוד אימות"
                                      type="tel" @keydown="restrictNonDigits"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="6" dir="ltr">
                        <v-text-field maxLength=9 v-model="purchaseRequest.idNumber" tabindex="4"
                                      :rules="[rules.required]" label="תעודת זהות"
                                      type="tel" @keydown="restrictNonDigits"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="purchaseRequest.cardHolderName" :rules="[rules.required]" tabindex="5"
                                      label="שם בעל הכרטיס"></v-text-field>
                    </v-col>
                    </v-row>
                     <v-row>
                    <v-col sm="6" md="6">
                        <span><b>סכום העסקה: </b></span>
                        <span readonly label="סכום העסקה">{{ purchaseSum }} ₪</span>
                    </v-col>
                    <v-col sm="6" md="6">
                   <v-checkbox
                       @change="useCredits"
                        class="rounded-checkbox"
                       dense
                       v-if="this.userCreditBalance > 0"
                     >
                       <template v-slot:label>
                         <v-row align="center">
                           <span>&nbsp;</span>
                           <span>רוצה לשלם בקרדיטים:</span>
                           <span>&nbsp;</span>
                           <span>{{ userCreditBalance }}</span>
                           <v-icon left>mdi-currency-btc</v-icon>
                         </v-row>
                       </template>
                     </v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-alert v-model="displayErrorAlert" color="blue" dismissible dark border="top"
                             icon="mdi-alert-circle" transition="scale-transition">
                        {{ errorMessage }}
                    </v-alert>
                </v-col>
            </v-row>
            <v-card-actions class="justify-center">
                <v-btn color="primary" @click="purchase" :disabled="!isPurchaseFormValid || !this.hasAuthCookie()"
                       :loading="purchaseButtonLoader">תשלום
                </v-btn>
            </v-card-actions>
        </v-form>
        <v-alert border="top" icon="mdi-lock" color="blue lighten-3" variant="tonal">
            <div class="text-h6"> קנייה בטוחה</div>
            התשלום באתר App-Winit מאובטח במנגנון הצפנה מתקדם ועומד בתקן SSL ובתקן PCI העולמי.
        </v-alert>
    </v-card>
</template>
<script>
export default {
    name: "PurchaseItemPage",
    data() {
        return {
            inputCardNumber: '',
            userCreditBalance: 0,
            displayErrorAlert: false,
            errorMessage: '',
            purchaseButtonLoader: false,
            purchaseRequest: {
                idNumber: "",
                cardNumber: "",
                cvv: "",
                expiry: "",
                cardHolderName: "",
                withCredits: false,
            },
            purchaseSum: 0,
            selectedProduct: {},
            selectedCardImage: 'default_card.png',
            isPurchaseFormValid: false,
            rules: {
                required: value => !!value || 'שדה חובה.',
                cardHolderName: value => {
                    if (value.length > 50) {
                        return 'שם ארוך מדי.'
                    }
                    const pattern = /\d/
                    if (pattern.test(value)) {
                        return 'שם לא יכול להכיל מספרים.'
                    }
                    return true
                }
            }
        }
    },
    async created() {
            if (this.hasAuthCookie()) {
                const currentPath = window.location.pathname
                const index = currentPath.lastIndexOf('/')
                const selectedProductId = currentPath.substring(index + 1)
                await this.getProduct(selectedProductId).then(data => {
                    this.selectedProduct = data
                    this.purchaseSum = Number(data.ticketPrice)
                }).catch(error => {
                    console.log('Error with getting product info > ' + error)
                })
                await this.getUserInfo().then(data => {
                  this.userCreditBalance = Number(data.creditBalance)
                }).catch(error => {
                  console.log('Error with getting user info > ' + error)
                })
            } else {
                alert('נדרש לבצע התחברות')
                this.$router.push(`/login`)
            }
        },
    methods: {
    hasAuthCookie() {
                        return document.cookie.match(/^(.*;)?\s*authToken\s*=\s*[^;]+(.*)?$/)
                    },
    useCredits(event) {
    if (event){
        if (this.userCreditBalance >= this.purchaseSum) {
            this.purchaseSum = 0;
        }
        this.purchaseSum -= this.userCreditBalance;
        this.purchaseRequest.withCredits = true;
     } else {
        if (this.userCreditBalance >= this.purchaseSum) {
            this.purchaseSum = this.selectedProduct.ticketPrice;
        }
        this.purchaseSum += this.userCreditBalance;
        this.purchaseRequest.withCredits = false;
    }
    },
    async getUserInfo() {
            const api = `${process.env.VUE_APP_WINIT_URL}/v1/users/me`
            return await fetch(api).then(response => {
              if (response.ok) {
                return response.json()
              }
              throw new Error('Error with get user info with status: ' + response.status);
            })
          },
        restrictNonDigits(event) {
            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab']

            if (!allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },
        async getProduct(productId) {
            console.log('request for get product info with id: ' + productId)
            return await fetch(`${process.env.VUE_APP_WINIT_URL}/v1/products/info/${productId}`).then(response => {
                if (response.ok) {
                    this.displayErrorAlert = false
                    this.errorMessage = ''
                    return response.json()
                }
                if (response.status === 401) {
                    alert('un-authorized')
                    this.$router.push(`/store`)
                }
                if (response.status === 404) {
                    this.displayErrorAlert = true
                    this.errorMessage = 'מוצר לא קיים, אנא נסה שנית'
                    this.isPurchaseFormValid = false
                }
                if (response.status === 500) {
                    this.displayErrorAlert = true
                    this.errorMessage = 'שגיאה בטעינת המוצר, אנא נסה שנית מאוחר יותר. אם הבעיה ממשיכה אנא פנה לתמיכה'
                    this.isPurchaseFormValid = false
                }
                throw new Error('Error with get product info with status: ' + response.status);
            })
        },
        is_valid_id_number(idNumber) {
            let id = String(idNumber).trim();
            if (id.length > 9 || isNaN(id)) return false;
            id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
            return Array.from(id, Number).reduce((counter, digit, i) => {
                const step = digit * ((i % 2) + 1);
                return counter + (step > 9 ? step - 9 : step);
            }) % 10 === 0;
        },
        is_valid_card_number(cardNumber) {
            let sum = 0;
            let digits = cardNumber.split("").reverse();
            let length = digits.length;
            if (length > 16) {
                return false
            }
            for (let i = 0; i < length; i++) {
                let digit = parseInt(digits[i]);
                if (i % 2 === 0) {
                    digit *= 2;
                    if (digit > 9) {
                        digit -= 9;
                    }
                }
                sum += digit;
            }
            return sum % 10 === 0;
        },
        purchase() {
            this.purchaseButtonLoader = true
            this.purchaseRequest.cardNumber = this.inputCardNumber.replace(/\s/g, "");
            console.log('purchasing with req: ' + JSON.stringify(this.purchaseRequest))
            if (this.hasAuthCookie()) {
                const api = `${process.env.VUE_APP_WINIT_URL}/v1/products/actions/ticket/buy`
                fetch(api, {
                    method: 'PUT',
                    body: JSON.stringify(this.purchaseRequest)
                },).then(response => {
                    this.purchaseButtonLoader = false
                    let status = response.status
                    if (response.ok) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'רכישה בוצע בהצלחה, הינך מועבר לעמוד המוצרים'
                        setTimeout(this.purchaseSuccessPopUp, 3000);
                    } else if (status === 401) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'נדרש לבצע התחברות לפני רכישת המוצר'
                    } else if (status === 400) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'שגיאה באחד השדות'
                    } else if (status === 402) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'שגיאה באמצעי התשלום'
                    } else if (status === 404) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'מוצר לא קיים'
                    } else if (status === 409) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'שגיאה ברכישת המוצר. אי אפשר לרכוש מוצר עצמי, או פעמיים או למוצר שלא פעיל'
                    } else if (status === 429) {
                        this.displayErrorAlert = true
                        this.errorMessage = 'בקשת רכישה כבר התקבלה'
                    } else {
                        throw new Error('Failed to purchase product: ' + status)
                    }
                }).catch(error => {
                    this.displayErrorAlert = true
                    this.errorMessage = 'שגיאה בשליחת הבקשה'
                    console.log('Error with purchasing product > ' + error)
                })
            } else {
                alert('נדרש לבצע התחברות')
                console.log('user is not logged in. redirecting to login before redirecting back to purchase page')
                this.$router.push(`/login`)
            }
        },
        purchaseSuccessPopUp() {
            this.displayErrorAlert = false
            this.$router.push(`/store`)
        }
    },
    watch: {
        'purchaseRequest.expiry': function () {
            if (this.purchaseRequest.expiry && this.purchaseRequest.expiry.length === 2) {
                this.purchaseRequest.expiry += '/'
            }
        },
        'inputCardNumber': function (newVal, oldVal) {
            let image = 'default_card.png'
            let creditCardExpectedLength = 16
            if (newVal === '' || oldVal === '-1') {
                this.selectedCardImage = image
                return;
            }
            if (newVal.startsWith('4')) {
                image = 'visa.png'
                creditCardExpectedLength = 16
            } else if (newVal.startsWith('5')) {
                image = 'mastercard.png'
                creditCardExpectedLength = 16
            } else if (newVal.startsWith('34') || this.inputCardNumber.startsWith('37')) {
                image = 'amex.png'
                creditCardExpectedLength = 15
            }

            this.selectedCardImage = this.getConfImagePath(image)

            if (this.inputCardNumber && this.inputCardNumber.length === 4) {
                this.inputCardNumber += ' '
            }

            if (creditCardExpectedLength === 16) {
                if (this.inputCardNumber && this.inputCardNumber.length === 9) {
                    this.inputCardNumber += ' '
                } else if (this.inputCardNumber && this.inputCardNumber.length === 14) {
                    this.inputCardNumber += ' '
                }
            } else if (creditCardExpectedLength === 15) {
                if (this.inputCardNumber && this.inputCardNumber.length === 11) {
                    this.inputCardNumber += ' '
                }
            }
        },
    }
}
</script>
<style>
.rounded-checkbox .v-input--selection-controls__input {
  border-radius: 50%;
}

.rounded-checkbox .v-input--selection-controls__ripple {
  border-radius: 50%;
}
</style>