<template>
    <v-container>
        <v-row class="text-center" justify="center">
            <v-col>
                <h1 class="blue--text text--lighten-1">הרשמה</h1>
            </v-col>
        </v-row>
        <v-form v-model="isRegistrationFormValid">
            <v-container>
                <v-row class="text-center" justify="center">
                    <v-col cols="6" md="3">
                        <v-text-field v-model="registrationRequest.firstName" :rules="[rules.required, rules.name]" tabindex="1"
                                      label="שם פרטי"></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field v-model="registrationRequest.lastName" :rules="[rules.required, rules.name]" tabindex="2"
                                      label="שם משפחה"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="text-center" justify="center">
                    <v-col cols="12" md="3" dir="ltr">
                        <v-text-field v-model="registrationRequest.phone" :rules="[rules.required, rules.phoneNumber]" tabindex="3"
                                      label="טלפון" class="pa-0" append-icon="mdi-phone-outline"
                                      maxLength=11 @keydown="restrictNonDigits" type="tel"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="text-center" justify="center">
                    <v-col cols="12" md="3" dir="ltr">
                        <v-text-field v-model="registrationRequest.email" :rules="[rules.required, rules.email]" tabindex="4"
                                      label="אימייל" append-icon="mdi-email-outline" type="email"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="text-center" justify="center">
                    <v-col cols="12" md="3">
                        <div>
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field prepend-icon="mdi-calendar" v-model="registrationRequest.dateOfBirth"
                                                  label="תאריך לידה" readonly v-bind="attrs" v-on="on"
                                                  :rules="[rules.required, rules.dateOfBirth]"></v-text-field>
                                </template>
                                <v-date-picker locale="he-IL" :first-day-of-week="0"
                                               v-model="registrationRequest.dateOfBirth"
                                               :active-picker.sync="activePicker" :max="today"
                                               @change="save"></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>
                </v-row>
                <v-alert
                        v-model="displayErrorAlert"
                        color="blue"
                        dismissible
                        dark
                        border="top"
                        icon="mdi-alert-circle"
                        transition="scale-transition"
                >
                    {{ errorMessage }}
                </v-alert>
                <v-row class="text-center" justify="center">
                    <v-col>
                        <v-btn @click="sendOtp" :disabled="!isRegistrationFormValid" :loading="buttonLoader">שלח קוד
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <v-container v-if="otpDialog">
                        <v-dialog width="50%" height="50%" v-model="otpDialog">
                          <v-card :loading="submitLoader">
                            <v-divider></v-divider>
                            <v-card-text>
                              <v-container>
                                <v-row class="text-center" justify="center" dir="ltr">
                                  <v-col cols="6">
                                    <p dir="rtl"> קוד אימות שהתקבל: </p>
                                    <v-otp-input dir="ltr" v-model="registrationRequest.otpCode" length="6" @finish="otpFinish" :rules="[rules.required, rules.otpCode]" @keydown="restrictNonDigits"></v-otp-input>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col />
                                </v-row>
                                <v-row class="text-center" justify="center">
                                  <v-col cols="6">
                                    <v-alert v-model="displayErrorAlert" color="blue" dismissible dark border="top" icon="mdi-alert-circle" transition="scale-transition">
                                      {{ errorMessage }}
                                    </v-alert>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-container>
        <v-row>
            <v-col/>
        </v-row>
        <v-row align="center">
            <v-divider></v-divider>
            <v-btn to="/login">כבר רשום? להתחברות לחץ כאן</v-btn>
            <v-divider></v-divider>
        </v-row>
        <v-row>
            <v-col/>
        </v-row>
        <v-row>
            <v-col/>
        </v-row>
    </v-container>
</template>


<script>
export default {
    name: 'RegistrationPage',
    methods: {
        restrictNonDigits(event) {
            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab']

            if (!allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },
        redirect(path) {
            this.$router.push(`/${path}`)
        },
        hasAuthCookie() {
            return document.cookie.match(/^(.*;)?\s*authToken\s*=\s*[^;]+(.*)?$/)
        },
        async otpFinish() {
            this.submitLoader = true
            await this.registration()
          },
        async registration() {
                console.log('going to register: ' + JSON.stringify(this.registrationRequest))
                const api = `${process.env.VUE_APP_WINIT_URL}/v1/registration`
                await fetch(api, {
                  method: 'POST',
                  headers: new Map([['Accept', 'application/json'], ['Content-Type', 'application/json']]),
                  body: JSON.stringify(this.registrationRequest)
                }).then(response => {
                  if (response.status === 201) {
                    alert('נרשמת בהצלחה!')
                    return;
                  }
                  throw new Error('error response from registration: ' + response.status)
                })
              },
        sendOtp() {
            this.buttonLoader = true
            this.displayErrorAlert = false
            this.errorMessage = ''
            this.otpDialog = true
            console.log('send request to send otp - registration with phone: ' + this.registrationRequest.phone)
            const api = `${process.env.VUE_APP_WINIT_URL}/v1/otp/send?phone=${this.registrationRequest.phone}`
            fetch(api, {
                method: 'POST',
                headers: new Map([['Accept', 'application/json'], ['Content-Type', 'application/json']]),
            }).then(response => {
                let status = response.status
                if (status === 200 || status === 429) {
                    this.otpDialog = true
                    this.displayErrorAlert = false
                    console.log('send otp success')
                } else {
                    this.otpDialog = false
                    this.displayErrorAlert = true
                    this.errorMessage = 'שגיאה בשליחת קוד, אנא נסה מאוחר יותר'
                    console.log("status from sendOtp: " + status)
                }
                this.buttonLoader = false
            }).catch(error => {
                console.log('Error with sendOtp fetch > ' + error)
                this.buttonLoader = false
            })
        },
        save(dateOfBirth) {
            this.$refs.menu.save(dateOfBirth)
        },
    },
    created() {
        if (this.hasAuthCookie()) {
            this.redirect('store')
        }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        "registrationRequest.phone": function() {
                if (this.registrationRequest.phone && this.registrationRequest.phone.length === 3) {
                        this.registrationRequest.phone += '-'
                    }
              }
    },
    data() {
        return {
            buttonLoader: false,
            submitLoader: false,
            displayErrorAlert: false,
            errorMessage: '',
            registrationRequest: {
                phone: null,
                email: null,
                otpCode: null,
                firstName: null,
                lastName: null,
                dateOfBirth: null
            },
            otpDialog: false,
            today: new Date().toISOString().slice(0, 10),
            activePicker: null,
            menu: false,
            isRegistrationFormValid: false,
            rules: {
                required: value => !!value || 'שדה חובה.',
                otpCode: value => {
                            const pattern = /^\s*-?\d{6}\s*$/
                            return pattern.test(value) || '.קוד חייב להיות 6 ספרות'
                          },
                phoneNumber: value => {
                    if (!value) return 'שדה חובה.'
                    if (value.length !== 11) return 'טלפון שגוי'
                    return true
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'אימייל שגוי.'
                },
                name: value => {
                    if (!value) return 'שדה חובה.'
                    const pattern = /\d/
                    if (pattern.test(value)) {
                        return 'שם לא יכול להכיל מספרים.'
                    }
                    if (value.length < 2 || value.length > 30) {
                        return 'שם חייב להיות בין 2 ל-30.'
                    }
                    return true
                },
                dateOfBirth: value => {
                    let today = new Date();
                    let birthDate = new Date(value);
                    let age = today.getFullYear() - birthDate.getFullYear();
                    let m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    if (age < 18) {
                        return 'חייב להיות מעל גיל 18.'
                    }
                    return true
                }
            }
        }
    },
}
</script>