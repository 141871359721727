import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import LoginPage from './components/LoginPage';
import RegistrationPage from './components/RegistrationPage';
import StorePage from './components/StorePage';
import PurchaseProductPage from './components/PurchaseProductPage'
import ProductInfoPage from './components/ProductInfoPage';

import App from './App.vue'

const routes = [{
    path: '/login',
    name: 'login',
    component: LoginPage
},
    {
        path: '/registration',
        name: 'registration',
        component: RegistrationPage
    },
    {
        path: '/store',
        alias: '',
        name: 'store',
        component: StorePage
    },
    {
            path: '/store/info/*',
            alias: '',
            name: 'productInfo',
            component: ProductInfoPage
        },
    {
        path: '/store/purchase/*',
        name: 'purchaseProduct',
        component: PurchaseProductPage
    }
]
const router = new VueRouter({
    mode: 'history',
    routes,
})

Vue.use(VueRouter);

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')

export default router